<template>
  <Title title="Familias" />
  <ContentMain>
    <template v-slot:list>
      <Search />
      <div class="content-grid-list-items scroll">
        <Item />
      </div>
    </template>
    <template v-slot:form>
      <p>Formulario de Familia</p>
    </template>
  </ContentMain>
  <Fab text="Familia" />
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Title: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Title" */ '@/modules/cms/components/Title.vue'
      )
    ),
    ContentMain: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ContentMain" */ '@/modules/cms/components/ContentMain.vue'
      )
    ),
    Search: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Search" */ '@/modules/cms/components/Search.vue'
      )
    ),
    Item: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Item" */ '@/modules/cms/components/Item.vue')
    ),
    Fab: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Fab" */ '@/modules/cms/components/Fab.vue')
    ),
  },
};
</script>
